/*
 * A class that handles adding OS-specific classes to elements based on user agent detection.
 * */

export default class OSClassHandler {
  constructor(elementSelector) {
    /*
     * Initializes an instance of OSClassHandler with a given element selector.
     * @param {string} elementSelector - The CSS selector used to target elements.
     * */
    this.elements = document.querySelectorAll(elementSelector);

    if (this.elements.length) {
      this.applyOSClass();
    } 
  }

  /*
   * Detects the operating system based on the user agent.
   * @returns {string} - The detected OS system as a class name (e.g. 'windowsOS', 'macOS').
   * */
  getOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('win')) return 'windowsOS';
    if (userAgent.includes('mac')) return 'macOS';
    if (userAgent.includes('linux')) return 'linuxOS';
    if (userAgent.includes('android')) return 'androidOS';
    if (userAgent.includes('iphone') || userAgent.includes('ipad')) return 'iOS';
    return 'unknownOS';
  }

  /*
   * Applies the OS-specific class to the elements.
   * @returns {void}
   * */
  applyOSClass() {
    const osClass = this.getOS();
    this.elements.forEach((element) => element.classList.add(osClass));
  }
}
